import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          title
        }
      }
      hero01: file(relativePath: { eq: "home-hero-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      hero02: file(relativePath: { eq: "home-hero-02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      hero03: file(relativePath: { eq: "home-hero-03.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata
  const { hero01, hero02, hero03 } = data

  return (
    <Layout>
      <Seo title={title} />
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(hero01))}
        className="hero home01"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <StaticImage
            src="../images/logo-white.png"
            alt="The Joy of Tai Chi"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        </div>
      </BackgroundImage>
      <section className="benefits">
        <div className="wrapper">
          <div className="block threecol">
            <div className="block col1">
              <h2>Better Life Quality</h2>
              <p>
                Increased Balance
                <br />
                Pain Releaser
                <br />
                Depression Fighter
                <br />
                Circulation Booster
                <br />
                More Energy
                <br />
                Improved Happiness
              </p>
            </div>
            <div className="block col2">
              <h2>Physical Rehabilitation</h2>
              <p>
                Improve Overall Health
                <br />
                Lover Blood Pressure
                <br />
                Improve Core Strength
                <br />
                Improve Productivity
                <br />
                <br />
                For: Heart Patients, Parkinson's Patients, Diabetes Patients
              </p>
            </div>
            <div className="block col3">
              <h2>Dependency Recovery</h2>
              <p>
                Synapses Building
                <br />
                Increase Brain Function
                <br />
                Adjusts Sleep Patterns
                <br />
                Stress Reliever
                <br />
                New Direction <br />
                Improve Mental Focus
                <br />
                Improve Outlook on Life
              </p>
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(hero02))}
        className="hero home02"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>
            If only one life has breathed easier because
            <br /> I have lived, this is to have succeeded!
          </h1>
          <h2>&mdash; Ralph Waldo Emerson</h2>
        </div>
      </BackgroundImage>
      <section className="classes">
        <div className="wrapper">
          <h1>Classes</h1>
          <p>
            Experience the Joy of Tai Chi through our series of focused on-site
            sessions. Our eight-week programs are tailored towards recovery,
            rehabilitation, and senior movement. Strengthen your phsyical and
            mental connection with each Tai Chi session.
          </p>
          <p>
            <Link className="btn" to="/classes">
              View Classes
            </Link>
          </p>
        </div>
      </section>
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(hero03))}
        className="hero home03"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>
            When I let go of what I am,
            <br /> I become what I might be.
          </h1>
          <h2>&mdash;Lao Tzu</h2>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default IndexPage
